import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Button, Col, message, Modal, Space, Table} from 'antd';
import {useHistory} from "react-router-dom";
import css from './user.module.less';
import {useLocation} from "react-router";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');
const useragent = require('useragent');

data = mobx.observable({
    users: [],
    query: {
        page: 1,
        pageSize: 100,
    },//查询条件
    add_user_data: {},//添加用户数据
    update_user_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});

let columns;

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/user?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.user.get_users(data.query);
    if (res.code != 0) return;
    data.users = res.data.item;
    data.total = res.data.total;
}

async function initData() {
    columns = [
        // {
        //     title: '_id',
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 150,
        //     ellipsis: true,
        // },
        {
            title: 'user_name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: 200,
        },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: 100,
        },
        // {
        //     title: 'display',
        //     dataIndex: 'display',
        //     key: 'display',
        //     valueEnum: ['inline', 'none'],
        //     valueType: 'select',
        //     width: 100,
        // },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
        {
            title: 'user_role_type',
            dataIndex: 'user_role_type',
            key: 'user_role_type',
            width: 150,
        },
        {
            title: 'business_id',
            dataIndex: 'id2',
            key: 'id2',
            width: 150,
        },
        {
            title: 'first_last_name',
            dataIndex: 'first_name',
            key: 'first_name',
            width: 200,
            render(first_name, record, index) {
                return `${record.first_name} ${record.second_name}`
            }
        },
        {
            title: 'landline',
            dataIndex: 'landline',
            key: 'landline',
            width: 200,
        },
        // {
        //     title: 'phone_num',
        //     dataIndex: 'phone_num',
        //     key: 'phone_num',
        //     editable: true,
        //     width: 150,
        // },
        // {
        //     title: 'evaluate_rate',
        //     dataIndex: 'evaluate_rate',
        //     key: 'evaluate_rate',
        //     width: 150,
        // },
        {
            title: 'all_money',
            dataIndex: 'all_money',
            key: 'all_money',
            width: 150,
        },
        {
            title: 'time_zone',
            dataIndex: 'time_zone',
            key: 'time_zone',
            width: 150,
        },
        {
            title: 'user_agent',
            dataIndex: 'user_agent',
            key: 'user_agent',
            width: 200,
            render(user_agent) {
                let agent = useragent.parse(user_agent);
                let os = agent.os.family;
                let family = agent.family;
                return `${os} / ${family}`;
            }
        },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            valueType: 'dateTime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        // {
        //     title: 'etime',
        //     dataIndex: 'etime',
        //     key: 'etime',
        //     valueType: 'dateTime',
        //     width: 160,
        //     render(etime) {
        //         return moment(etime).format('yyyy-MM-DD HH:mm')   //1是男，2是女
        //     }
        // },
        // {
        //     title: 'p_user',
        //     dataIndex: 'p_user',
        //     key: 'p_user',
        //     width: 150,
        //     ellipsis: true,
        // },
        {
            title: 'company_name',
            dataIndex: ['company', 'company_name'],
            key: 'company.company_name',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'company_department',
            dataIndex: ['company', 'department'],
            key: 'company.department',
            width: 150,
            ellipsis: true,
        },
        // {
        //     title: 'company_role',
        //     dataIndex: ['company', 'role'],
        //     key: 'company.role',
        //     width: 150,
        //     ellipsis: true,
        // },
        {
            title: 'company_phone',
            dataIndex: ['company', 'phone_num'],
            key: 'company.phone_num',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'company_website',
            dataIndex: ['company', 'website'],
            key: 'company.website',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'street_address',
            dataIndex: ['company', 'address', 'street_address'],
            key: 'company.address.street_address',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'city',
            dataIndex: ['company', 'address', 'city'],
            key: 'company.address.city',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'state',
            dataIndex: ['company', 'address', 'state'],
            key: 'company.address.state',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'zip_code',
            dataIndex: ['company', 'address', 'zip_code'],
            key: 'company.address.zip_code',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'street_address2',
            dataIndex: ['company', 'address', 'street_address2'],
            key: 'company.address.street_address2',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'register_add_info_error_arr',
            dataIndex: 'register_add_info_error_arr',
            key: 'register_add_info_error_arr',
            width: 250,
            ellipsis: true,
            render(register_add_info_error_arr) {
                if (commonUtils.isEmpty(register_add_info_error_arr)) {
                    return '';
                }
                // 按照register_add_info_error的ctime 倒序排列
                register_add_info_error_arr.sort((a, b) => {
                    return moment(b.ctime).isAfter(moment(a.ctime)) ? 1 : -1;
                });
                return !commonUtils.isEmpty(register_add_info_error_arr) ? JSON.stringify(register_add_info_error_arr) : '';
            }
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 300,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_user_data = record;
                        data.is_show_update_dialog = true;
                    }}>edit</Button>
                    <Button type={'primary'} onClick={() => {
                        let url = config.url.user_client_url + `/login/user?admin_token=${store.persist.token}&user_name=${record.user_name}`;
                        window.open(url, '_blank');
                    }}>to user page</Button>
                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delUser(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ];
    await getList(data.query.page, data.query.pageSize);
}

async function updateUser(newData) {
    data.update_user_data = newData;
    let res = await api.user.update_user(data.update_user_data);
    if (res.code != 0) return;
    message.success('update user success');
    data.is_show_update_dialog = false;
    history.go(0);
}

async function addUser(newData) {
    data.add_user_data = newData;
    let res = await api.user.add_user(data.add_user_data);
    if (res.code != 0) return;
    message.success('add user success');
    data.is_show_add_dialog = false;
    getList();
}

async function delUser(data) {
    let res = await api.user.del_user({_id: data._id});
    if (res.code != 0) return;
    message.success('del user success');
    getList();
}

// create observer
let User = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.users)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.users)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                // style={{display:'inline'}}
                rowKey="_id"
                columns={columns}
                dataSource={data.users}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true,
                    showSizeChanger: true,
                    defaultCurrent: 1,
                    total: data.total,
                    onChange: onChangePage,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '400', '800'],
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_user_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_user_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateUser(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_user_data = {};
                                 }}
            />
        </div>
    );
})
export default User;
