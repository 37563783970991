let isProEnv = process.env.NODE_ENV === 'production';
let config = {
    url: {
        baseUrl: isProEnv ? 'https://api.interpretown.com' : 'http://localhost:8002',
        adminBaseUrl: isProEnv ? 'https://api-admin.interpretown.com' : 'http://localhost:8001',
        imgDefaultUrl: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png',
        simple_service_host: isProEnv ? 'https://api-singleservice.interpretown.com' : 'http://localhost:8003',
        user_client_url: isProEnv ? 'https://alpha.interpretown.com' : 'http://localhost:3010',
        interpreter_client_url: isProEnv ? 'https://app-interpreter.interpretown.com' : 'http://localhost:3011',
        // 公司主页 由其他人员维护
        company_home: 'https://www.interpretown.com/',
        company_home_host: 'https://www.interpretown.com/',
    },
    stripe: {
        publicKey: 'pk_live_51Nej0gG0rf2e5GeigD8SLvKeJGjLsz8cbOEJFB2gBO8GAMBlEfPYnmwaV0bUTq5OLCQCJzraAHLefN9MGJRYcZeX00wRLR6ets',
    },
    websocket: {
        url: isProEnv ? 'wss://api-singleservice.interpretown.com/ws' : 'ws://localhost:8083',
        action_type: {
            receive_order: 'receive_order',
            receive_chat_msg: 'receive_chat_msg',
            receive_order_change: 'receive_order_change',
            receive_user_not_online: 'receive_user_not_online',
            receive_agora_call: 'receive_agora_call', // 收到agora呼叫
            receive_refuse_agora_call: 'receive_refuse_agora_call', // 收到对方拒绝agora呼叫
            cb_receive_agora_call_cancel: 'cb_receive_agora_call_cancel', // 收到对方拒绝agora呼叫
        }
    },
    valid_code_time: 120 * 1000,//验证码倒计时时间  单位毫秒
    freshdesk: {
        token: '77d0fd7b-d312-41bb-9033-b4c2c8a2983d',
        host: 'https://wchat.freshchat.com',
    }
}
module.exports = config;
