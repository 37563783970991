import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {observer} from "mobx-react";
import {
    Breadcrumb,
    Button,
    Col,
    Divider,
    Form,
    Image,
    Input,
    InputNumber,
    Layout,
    Menu,
    message, Modal,
    Row,
    Space,
    Table, Tooltip
} from 'antd';
import {Link, useHistory} from "react-router-dom";
import intp_logo from '../../assets/img/home/intp_logo.png';
import css from './interpreter.module.less';
import {useLocation} from "react-router";
import Sider from "antd/lib/layout/Sider";
import SubMenu from "antd/lib/menu/SubMenu";
import AddUpdateDataDialog from "../../component/AddUpdateDataDialog/addUpdateDataDialog";
import TableQueryFilter from "../../component/tableQueryFilter/tableQueryFilter";

let mobx = require('mobx');
let store = require('../../store');
let data;
let api = require('src/api');
let moment = require('moment');
let history;
let config = require('commonProject/config/config');
let commonUtils = require('commonProject/utils/Common');

data = mobx.observable({
    interpreters: [],
    query: {
        page: 1,
        pageSize: 10,
    },//查询条件
    add_interpreter_data: {},//添加用户数据
    update_interpreter_data: {},//要更新的用户数据
    pageTop: 10,
    pageBottom: 1,
    total: 0,
    editing: false,
    is_show_add_dialog: false,//显示添加的对话框
    is_show_update_dialog: false,//显示更新的对话框
});
let columns;

//点击了分页按钮的回调函数
async function onChangePage(page, pageSize) {
    commonUtils.historyPushRefresh(history, '/home_login/interpreter?page=' + page + '&pageSize=' + pageSize);
}

async function getList(page = 1, pageSize = 100) {
    data.query.page = page;
    data.query.pageSize = pageSize;
    let res = await api.interpreter.get_interpreters(data.query);
    if (res.code != 0) return;
    data.interpreters = res.data.item;
    for (let interpreter of data.interpreters) {
        interpreter.interpret_langs = interpreter.interpret_langs.map(item => {
            item.title = item.language_name;
            return item;
        });
        interpreter.certs = interpreter.certs.map(item => {
            item.title = item.cert_name;
            return item;
        });
    }

    data.total = res.data.total;
}

async function getInterpreterLanguages() {
    let res = await api.interpret_lang.get_interpret_langs({pageSize: 1000});
    if (res.code != 0) return;
    let arr = res.data.item;
    for (let item of arr) {
        item.title = item.language_name;
    }
    store.persist.interpret_langs = arr;
}

async function getCerts() {
    let res = await api.cert.get_certs({pageSize: 1000});
    if (res.code != 0) return;
    let arr = res.data.item;
    for (let item of arr) {
        item.title = item.cert_name;
    }
    store.persist.certs = arr;
}

async function initData() {
    getInterpreterLanguages();
    getCerts();
    getList(data.query.page, data.query.pageSize);

    columns = [
        // {
        //     title: '_id',
        //     dataIndex: '_id',
        //     key: '_id',
        //     width: 150,
        //     ellipsis: true,
        // },
        {
            title: 'id2',
            dataIndex: 'id2',
            key: 'id2',
            width: 150,
        },
        {
            title: 'user_name',
            dataIndex: 'user_name',
            key: 'user_name',
            width: 150,
        },
        // {
        //     title: 'user_password',
        //     dataIndex: 'user_password',
        //     key: 'user_password',
        //     width: 200,
        // },
        {
            title: 'status',
            dataIndex: 'status',
            key: 'status',
            width: 80,
        },
        {
            title: 'work_status',
            dataIndex: 'work_status',
            key: 'work_status',
            width: 125,
        },
        {
            title: 'long_connect_status',
            dataIndex: 'long_connect_status',
            key: 'long_connect_status',
            width: 140,
            render(long_connect_status) {
                return long_connect_status == 'connected' ? 'Y' : 'N';
            }
        },
        {
            title: 'first_name',
            dataIndex: 'first_name',
            key: 'first_name',
            width: 120,
        },
        {
            title: 'last_name',
            dataIndex: 'second_name',
            key: 'second_name',
            width: 120,
        },
        {
            title: 'all_money',
            dataIndex: 'all_money',
            key: 'all_money',
            width: 120,
        },
        {
            title: 'gender',
            dataIndex: 'gender',
            key: 'gender',
            width: 100,
        },
        {
            title: 'work_phone_num',
            dataIndex: 'work_phone_num',
            key: 'work_phone_num',
            width: 200,
        },
        {
            title: 'phone_num',
            dataIndex: 'phone_num',
            key: 'phone_num',
            width: 200,
        },
        {
            title: 'interpret_langs',
            dataIndex: 'interpret_langs',
            key: 'interpret_langs',
            width: 180,
            valueEnum: store.persist.interpret_langs,
            valueType: 'checkbox',
            render(interpret_langs) {
                return interpret_langs.map(interpret_lang => {
                    return interpret_lang.language_name + '\r\n';
                })
            }
        },
        {
            title: 'certs',
            dataIndex: 'certs',
            key: 'certs',
            width: 150,
            valueEnum: store.persist.certs,
            valueType: 'checkbox',
            render(certs) {
                return certs.map(cert => {
                    return cert.cert_name + '\r\n';
                })
            }
        },
        {
            title: 'country_type',
            dataIndex: 'country_type',
            key: 'country_type',
            width: 200,
            valueType: 'select',
            valueEnum: ['usa', 'other'],
        },
        // {
        //     title: 'login_ip',
        //     dataIndex: 'login_ip',
        //     key: 'login_ip',
        // },
        // {
        //     title: 'mark',
        //     dataIndex: 'mark',
        //     key: 'mark',
        // },
        {
            title: 'display',
            dataIndex: 'display',
            key: 'display',
            valueEnum: ['inline', 'none'],
            valueType: 'select',
            width: 200,
        },
        {
            title: 'email',
            dataIndex: 'email',
            key: 'email',
            width: 200,
        },
        {
            title: 'evaluate_rate',
            dataIndex: 'evaluate_rate',
            key: 'evaluate_rate',
            width: 200,
        },
        {
            title: 'notify_on_wait_customer',
            dataIndex: 'notify_on_wait_customer',
            key: 'notify_on_wait_customer',
            width: 200,
        },
        {
            title: 'advance_notice',
            dataIndex: 'advance_notice',
            key: 'advance_notice',
            width: 200,
        },
        {
            title: 'time_zone',
            dataIndex: 'time_zone',
            key: 'time_zone',
            width: 200,
        },
        {
            title: 'today_serviced_time',
            dataIndex: 'today_serviced_time',
            key: 'today_serviced_time',
            width: 200,
        },
        {
            title: 'today_wait_time',
            dataIndex: 'today_wait_time',
            key: 'today_wait_time',
            width: 200,
        },
        {
            title: 'history_wait_time',
            dataIndex: ['interpreter_wait_time'],
            key: 'interpreter_wait_time',
            width: 200,
            render: (text, record) => {
                let interpreter_wait_time = record.interpreter_wait_time;
                if (commonUtils.isEmpty(interpreter_wait_time)) return null;

                // 生成内容字符串
                const content = interpreter_wait_time.map(item => {
                    return moment(item.ctime).format('YYYY-MM-DD') + ', ' + item.wait_time_minute + ' mins, ' + `$${item.total_price}` + '\r\n';
                }).join('');

                // 返回带有Tooltip的内容
                return (
                    <Tooltip title={
                        interpreter_wait_time.map(item => {
                            return (
                                <div
                                    style={{whitespace: 'nowrap'}}>  {moment(item.ctime).format('YYYY-MM-DD') + ', ' + item.wait_time_minute + ' mins, ' + `$${item.total_price}` + '\r\n'}  </div>
                            )
                        })
                    }>
                        {/* 这里可以根据需要切割内容，以适应列的宽度 */}
                        {content.length > 60 ? content.slice(0, 60) + '...' : content}
                    </Tooltip>
                );
            }
        }
        ,
        {
            title: 'total_serviced_time',
            dataIndex: 'total_serviced_time',
            key: 'total_serviced_time',
            width: 200,
        },
        // {
        //     title: 'token',
        //     dataIndex: 'token',
        //     key: 'token',
        //     width: 200,
        // },
        {
            title: 'ctime',
            dataIndex: 'ctime',
            key: 'ctime',
            valueType: 'dateTime',
            width: 160,
            render(ctime) {
                return moment(ctime).format('yyyy-MM-DD HH:mm')
            }
        },
        {
            title: 'etime',
            dataIndex: 'etime',
            key: 'etime',
            valueType: 'dateTime',
            width: 160,
            render(etime) {
                return moment(etime).format('yyyy-MM-DD HH:mm')   //1是男，2是女
            }
        },
        {
            title: 'zoom_link',
            dataIndex: 'zoom_link',
            key: 'zoom_link',
            width: 150,
            ellipsis: true,
        },
        {
            title: 'Action',
            key: 'action',
            fixed: 'right',
            width: 300,
            render: (text, record) => (
                <Space size="middle">
                    <Button type={'primary'} onClick={() => {
                        data.update_interpreter_data = record;
                        data.is_show_update_dialog = true;
                    }}>edit</Button>
                    <Button type={'primary'} onClick={() => {
                        let url = config.url.interpreter_client_url + `/login/user?admin_token=${store.persist.token}&user_name=${record.user_name}`;
                        window.open(url, '_blank');
                    }}>to intp page</Button>
                    <Button type={'danger'} onClick={() => {
                        Modal.confirm({
                            content: 'are you sure delete this record',
                            onOk: function () {
                                delData(record)
                            },
                        })
                    }}>Delete</Button>
                </Space>
            ),
        },
    ]
}

async function updateData(newData) {
    data.update_interpreter_data = newData;
    let res = await api.interpreter.update_interpreter(data.update_interpreter_data);
    if (res.code != 0) return;
    message.success('update interpreter success');
    data.is_show_update_dialog = false;
}

async function addData(newData) {
    data.add_interpreter_data = newData;
    let res = await api.interpreter.add_interpreter(data.add_interpreter_data);
    if (res.code != 0) return;
    message.success('add interpreter success');
    data.is_show_add_dialog = false;
    getList();
}

async function delData(data) {
    let res = await api.interpreter.del_interpreter({_id: data._id});
    if (res.code != 0) return;
    message.success('del interpreter success');
    getList();
}

// create observer
let Interpreter = observer(function () {

    history = useHistory();
    let params = commonUtils.query2ObjParams(useLocation().search);
    let type = params.type;
    useEffect(() => {
        data.query.page = params.page ?? 1;
        data.query.pageSize = params.pageSize ?? 100;
        initData();
    }, []);

    if (commonUtils.isEmpty(data.interpreters)) return <div/>;
    return (
        <div className={css.containBox}>
            <TableQueryFilter columns={columns}
                              onSure={(query) => {
                                  data.query = query;
                                  getList()
                              }}/>
            {/*<div>{JSON.stringify(data.interpreters)}</div>*/}
            <Col>
                <Button type={'primary'} onClick={() => {
                    data.is_show_add_dialog = true;
                }}>add new</Button>
            </Col>
            <Table
                rowKey="_id"
                columns={columns}
                dataSource={data.interpreters}
                scroll={{x: 1000, y: 900}}
                pagination={{
                    current: data.query.page, pageSize: data.query.pageSize,
                    showQuickJumper: true, showSizeChanger: true,
                    total: data.total, onChange: onChangePage,
                    pageSizeOptions: ['10', '20', '50', '100', '200', '500'],
                }}
            />
            {/*添加新数据的弹窗*/}
            <AddUpdateDataDialog columns={columns}
                                 is_show_dialog={data.is_show_add_dialog}
                                 onSure={(newData) => {
                                     addData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_add_dialog = false;
                                     data.add_interpreter_data = {};
                                 }}
            />
            {/*更新原来数据的弹窗*/}
            <AddUpdateDataDialog dataSource={data.update_interpreter_data}
                                 columns={columns}
                                 is_show_dialog={data.is_show_update_dialog}
                                 onSure={(newData) => {
                                     updateData(newData);
                                 }}
                                 onCancel={() => {
                                     data.is_show_update_dialog = false;
                                     data.update_interpreter_data = {};
                                 }}
            />
        </div>
    );
})
export default Interpreter;
